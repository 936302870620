import {
    mapGetters,
    mapActions
} from 'vuex'
// ? SERVICE
import service    from '@/services/soporte'
import authStore  from '@/store/modules/authStore'
import DialogForm from '@/components/Dialogs/DialogForm/DialogForm.vue'
export default {
    name : "CreateTicket",
    components : {
        DialogForm
    },
    props : {
        dialog : {
            type    : Boolean,
            default : false
        },
        isNew : {
            type    : Boolean,
            default : true
        },
        oldObject : {
            type    : Object,
            default : () => {}
        },
        user : {
            type    : Boolean,
            default : false
        }
    },
    data : () => ({
        title       : "Nuevo",
        valid       : true,
        lazy        : false,
        idTrTicket  : -1,
        folio       : undefined,
        titulo      : undefined,
        // evento      : undefined,
        evento      : { id : 0, name: "", description : "" },
        editUsuario : true,
        usuario     : undefined,
        sucursal    : undefined,
        servicio    : undefined,
        descripcion : undefined,
        estatus     : undefined,
        files       : undefined,
        tipoUsuario : undefined,
        items       : {
            evento   : [],
            usuario  : [],
            sucursal : [],
            servicio : []
        },
        errores     : {
            folio       : false,
            titulo      : false,
            producto    : false,
            producto    : false,
            producto    : false,
            descripcion : false
        },
        rules       : {
            folio       : [],
            titulo      : [v => !!v || 'El titulo es requerido'],
            descripcion : [v => !!v || 'La descripción es requerida'],
            files       : [
                v => v == undefined ? true : v.length <= 3 || "Solo se permite un maximo de 3 archivos"
            ]
        }
    }),
    watch : {
        'oldObject' : function() {
            this.idTrTicket     = this.oldObject.id_soporteTrTicket;
            this.folio          = this.oldObject.folio;
            this.estatus        = this.oldObject.id_baseCtEstatus;
            this.titulo         = this.oldObject.titulo;
            this.evento         = { id : this.oldObject.id_soporteCtEvento, name : this.oldObject.evento, description : this.oldObject.evento };
            this.servicio       = this.oldObject.id_soporteCtServicio;
            this.usuario        = this.oldObject.id_adminCtUser;
            this.editUsuario    = this.oldObject.id_adminCtUser != undefined;
            this.sucursal       = this.oldObject.id_adminCtSucursal;
            this.descripcion    = this.oldObject.descripcion;
            // this.files          = this.oldObject.files;
            this.fechaU         = this.oldObject.fechaU;
        },
    },
    computed : {
        ...mapGetters("Ticket", {
            dataSource : "getDataSource"
         }),
         itemsSucursal(){
            let itemsSucursal = this.items.sucursal;
            if( this.usuario != undefined ){
                itemsSucursal = itemsSucursal.filter( data => data.idUser == this.usuario );
            }
            return itemsSucursal;
        }
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Ticket",  ["setDataSource"] ),
        eventClose () {
            this.reset();
            this.evento = { id : 0, name: "", description : "" };
            this.setDialog({
                key    : "dlgCreateT",
                status : false
            });
        },
        eventGen () {
            this.isNew ? this.createTicket() : this.updateTicket();
        },
        createTicket(){
            if( this.validate () ) {
                let {objTicket, files} = this.buildObject();
                let arrInfo = []
                arrInfo.push(this.$JsTable.getFormat("soporteTrTicket",  "Insert", [ objTicket ],  ["id_soporteTrTicket"]));
                service.SopC_ticket( { data:arrInfo, files:files} ).then( result => {
                    if( this.$Msg.fn( {data : result, message : "Ticket creado correctamente" } )) {
                        this.eventClose();
                        this.readTableTicket();
                    }
                })
            }
        },
        updateTicket(){
            if( this.validate () ) {
                let {objTicket, files} = this.buildObject();
                let arrInfo  = [];
                let trTicket = this.$fn.getDiffObj( [this.oldObject],[objTicket],["id_soporteTrTicket","updated_at"])
                if( trTicket != 0 ) {
                    arrInfo.push(this.$JsTable.getFormat("soporteTrTicket",  "Update", trTicket,  ["id_soporteTrTicket"]));
                    service.SopU_ticket( { data:arrInfo, files:files} ).then( result => {
                        if( this.$Msg.fn( {data : result, message : "Ticket actualizado correctamente" } )) {
                            this.eventClose();
                            this.readTableTicket();
                        }
                    })
                } else {
                    this.$Msg.error( "No se ha realizado ningún cambio" )
                }
            }
        },
        getTitle() {
            return this.isNew ? "Nuevo" : "Editar";
        },
        getColSize( type ) {
            return type == "sm" ? 12 : this.tipoUsuario == 3 ? 4 : 6;
        },
        buildObject(){
            let objTicket = {
                id_soporteTrTicket     : this.idTrTicket,
                folio                  : this.folio,
                titulo                 : this.titulo,
                descripcion            : this.descripcion,
                // id_soporteCtEvento     : this.evento,
                id_soporteCtEvento     : this.evento.id,
                id_soporteCtServicio   : this.servicio,
                id_baseCtEstatus       : 1,
                id_adminCtUser         : this.usuario,
                id_adminCtSucursal     : this.sucursal,
                id_soporteTrTicketBase : null,
                activo                 : 1
            }
            let files =  this.files;
            return {objTicket, files};
        },
        // ? Validaciones del form
        validate () {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
            this.idTrTicket = -1;
            if ( this.tipoUsuario == 3 )
                this.usuario = authStore.getters.getUser().id_adminCtUser
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    },
    created() {
        service.SopR_dataCreateTicket().then( result => {
            this.items.evento   = this.$JsTable.toObj( result.data.evento );
            this.items.usuario  = this.$JsTable.toObj( result.data.users );
            this.items.sucursal = this.$JsTable.toObj( result.data.sucursal );
            this.items.servicio = this.$JsTable.toObj( result.data.servicio );
        })
        this.tipoUsuario = authStore.getters.getUser().idPerfil;
        // Validaciones si el usuario es administrador o responsable
        if ( this.tipoUsuario != 3 ){
            this.rules.usuario  = [v => !!v || 'El usuario es requerido'];
            this.rules.servicio = [v => !!v || 'El servicio es requerido'];
            this.rules.sucursal = [v => !!v || 'La sucursal es requerida'];
            this.rules.evento   = [v => !!v || 'El evento es requerido'];
        }
        else
            this.usuario = authStore.getters.getUser().id_adminCtUser
    }
}
